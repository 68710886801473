import React from 'react'
import { Link } from 'react-router-dom';


class Header extends React.Component {
  handleClick=(e)=>{

  }
	render() {
		return (
<div>
<div>

  {/* Topbar Start */}
  <div className="container-fluid bg-dark px-5 d-none d-lg-block">
    <div className="row gx-0">
      <div className="col-lg-8 text-center text-lg-start mb-2 mb-lg-0">
        <div className="d-inline-flex align-items-center" style={{height: 45}}>
          <small className="me-3 text-light"><i className="fa fa-map-marker-alt me-2" />Rue 18 Janvier 9000 Béja</small>
          <small className="me-3 text-light"><i className="fa fa-phone-alt me-2" />+216 97 732 156</small>
          <small className="text-light"><i className="fa fa-envelope-open me-2" />hello@icstartup.digital</small>
        </div>
      </div>
      <div className="col-lg-4 text-center text-lg-end">
        <div className="d-inline-flex align-items-center" style={{height: 45}}>
          <a className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" href="https://www.facebook.com/icstartup" target = "_blank" rel='noreferrer'><i className="fab fa-facebook-f fw-normal" /></a>
          <a className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" href="https://www.linkedin.com/company/icstartup/mycompany/"target = "_blank" rel='noreferrer'><i className="fab fa-linkedin-in fw-normal" /></a>
          <a className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" href="https://www.instagram.com/international_coach_start_up/"target = "_blank" rel='noreferrer'><i className="fab fa-instagram fw-normal" /></a>
          <a className="btn btn-sm btn-outline-light btn-sm-square rounded-circle" href="https://www.youtube.com/channel/UCB_nfFYEcAd8R0TxpaO6pUA"target = "_blank" rel='noreferrer'><i className="fab fa-youtube fw-normal" /></a>
        </div>
      </div>
    </div>
  </div>
  {/* Topbar End */}
  {/* Navbar & Carousel Start */}
  <div className="container-fluid position-relative p-0">
    <nav className="navbar navbar-expand-lg navbar-dark px-5 py-3 py-lg-0" style={{backgroundColor:'#082453'}}>
      <Link to="/">
        <img src="./assets/img/Logo1.png" className="logo" id="logo1" alt='logo' title='logo'style={{width:160,marginTop:12}} />
        
      </Link>
      <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
        <span className="fa fa-bars" />
      </button>
      <div className="collapse navbar-collapse" id="navbarCollapse">
        <div className="navbar-nav ms-auto py-0">
          <Link to="/" className="nav-item nav-link active">Accueil</Link>
          <Link to="./About" className="nav-item nav-link">Qui Sommes-Nous?</Link>
          <div className="nav-item dropdown">
            <Link href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Nos Services</Link>
            <div className="dropdown-menu m-0">
              <Link to="./Developpementweb" className="dropdown-item">Developpement Web </Link>

              <Link to="./Developpementmobile" className="dropdown-item">Developpement Mobile</Link>

              <Link to="./Developpementlogiciel" className="dropdown-item">Developpement Logiciel</Link>

              <Link to="./Design"className="dropdown-item">Design Graphique</Link>

              <Link to="./Marketingdigital" className="dropdown-item">Marketing Digital</Link>

              <Link to="/Audiovisuelle" className="dropdown-item" >Production Audiovisuelle</Link>

              <Link to="./Formationtechnologique" className="dropdown-item">Formation Technologique</Link>

              <Link to="./Incubation" className="dropdown-item">Incubation</Link>

              <Link to="./Conseilconsulting" className="dropdown-item">Conseil &amp; Consulting</Link>
              <Link to="./Coworkingspace" className="dropdown-item">Coworking Space</Link>
            </div>
          </div>
          <a href="https://blog.icstartup.digital" className="nav-item nav-link">Blog</a>
          <Link to="./Contact" className="nav-item nav-link">Contactez-Nous</Link>
        </div>
        <Link to="./Devis" className="btn btn-primary py-2 px-4 ms-3">Demander Devis</Link>
      </div>
    </nav>
  </div></div>
  
</div>

	);
}
}


export default Header
