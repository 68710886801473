import React from 'react';
// import { useRef } from 'react'; 
import emailjs from 'emailjs-com';
import swal from 'sweetalert';
import{Link} from 'react-router-dom';

class Footer extends React.Component {
  
  render() {




    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs.sendForm('service_ojnclmr', 'template_9mu98md',  '0lWVIqTi320ovPKAL')
        .then((result) => {
            
          swal("seccessful !...", "Votre message a été envoyé !", "success");
        }, (error) => {
            swal("Error!...", "Vous avez une erreur de saisie !", "error");
        });
    };
    return (
      <div>
        <div>
          <div className="container-fluid bg-dark text-light mt-5 wow fadeInUp" data-wow-delay="0.1s">
            <div className="container">
              <div className="row gx-5">
                <div className="col-lg-4 col-md-6 footer-about">
                  <div className="d-flex flex-column align-items-center justify-content-center text-center h-100 bg-primary p-4">
                    <Link to="/">
                      <img src="./assets/img/Logo1.png" style={{ width: 160 }} alt='logo' title='logo' />
                    </Link >

                    <form  onSubmit={sendEmail} method="POST">
                      <div className="input-group">
                        <input type="text" name='from_email' className="form-control border-white p-3" placeholder="Votre Email" />
                        <button className="btn btn-dark" type='submit'>Envoyer</button>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="col-lg-8 col-md-6">
                  <div className="row gx-5">
                    <div className="col-lg-4 col-md-12 pt-5 mb-5">
                      <div className="section-title section-title-sm position-relative pb-3 mb-4">
                        <h3 className="text-light mb-0">Entrer en contact</h3>
                      </div>
                      <div className="d-flex mb-2">
                        <i className="bi bi-geo-alt text-primary me-2" />
                        <p className="mb-0">Rue 18 Janvier 9000 Béja</p>
                      </div>
                      <div className="d-flex mb-2">
                        <i className="bi bi-envelope-open text-primary me-2" />
                        <p className="mb-0">hello@icstartup.digital</p>
                      </div>
                      <div className="d-flex mb-2">
                        <i className="bi bi-telephone text-primary me-2" />
                        <p className="mb-0">+216 97 732 156</p>
                      </div>
                      <div className="d-flex mt-4">

                        <a href="https://www.facebook.com/icstartup" className="btn btn-primary btn-square me-2" target="_blank" rel='noreferrer' ><i className="fab fa-facebook-f fw-normal" /></a>
                        <a href="https://www.linkedin.com/company/icstartup/mycompany" className="btn btn-primary btn-square me-2" target="_blank" rel='noreferrer' ><i className="fab fa-linkedin-in fw-normal" /></a>
                        <a href="https://www.instagram.com/international_coach_start_up" className="btn btn-primary btn-square" target="_blank" rel='noreferrer' ><i className="fab fa-instagram fw-normal" /></a>
                        <a href="https://www.youtube.com/channel/UCB_nfFYEcAd8R0TxpaO6pUA" className="btn btn-primary btn-square" target="_blank" rel='noreferrer' style={{ marginLeft: 9 }}><i className="fab fa-youtube fw-normal" /></a>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12 pt-0 pt-lg-5 mb-5" >
                      <div className="section-title section-title-sm position-relative pb-3 mb-4">
                        <h3 className="text-light mb-0">Quick Links</h3>
                      </div>
                      <div className="link-animated d-flex flex-column justify-content-start">
                        <Link to="/" className="text-light mb-2"><i className="bi bi-arrow-right text-primary me-2" />Accueil</Link>
                        <Link to="./About" className="text-light mb-2"><i className="bi bi-arrow-right text-primary me-2" />Qui Sommes-Nous?</Link>
                        <Link to="" className="text-light mb-2"><i className="bi bi-arrow-right text-primary me-2" />Nos Services</Link>
                        <a href="https://blog.icstartup.digital" className="text-light mb-2"><i className="bi bi-arrow-right text-primary me-2" />Blog</a>
                        <Link to="./Contact" className="text-light" href="contact.html"><i className="bi bi-arrow-right text-primary me-2" />Contactez-Nous</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid text-white" style={{ background: '#061429' }}>
            <div className="container text-center">
              <div className="row justify-content-end">
                <div className="col-lg-8 col-md-6">
                  <div className="d-flex align-items-center justify-content-center" style={{ height: 75 }}>
                    <p className="mb-0">Copyright © 2022 <Link to="/" className="text-white border-bottom" >Icstartup</Link> </p>
                    {/*/*** This template is free as long as you keep the footer author’s credit link/attribution link/backlink. If you'd like to use the template without the footer author’s credit link/attribution link/backlink, you can purchase the Credit Removal License from "https://htmlcodex.com/credit-removal". Thank you for your support. *** /*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>

    );
  }
}



export default Footer
