import React from 'react'

import { Link , NavLink } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.min.css';
class Home extends React.Component {

  
 
  
  
  render() {
    return (
      <div>
        <div>
          <div id="header-carousel" className="carousel slide carousel-fade" data-bs-ride="carousel">
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img className="w-100" src="./assets/img/carousel-1.jpg" alt="slider-1" title='image' />
                <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                  <div className="p-3" style={{ maxWidth: 900 }}>
                    <h1 className="display-1 text-white mb-md-4 animated zoomIn" style={{fontFamily:"Zilla Slab",letterSpacing:5}}>Communiquer, c'est partager.</h1>
                    <h5 className="text-white text-uppercase mb-3 animated slideInDown ">Icstartup accompagne les marques dans leur stratégie de communication.</h5>
                    <NavLink to="./Devis" className="btn btn-lg btn-primary rounded"  rel = "noreferrer" style={{width:130,marginLeft:-65}} onclick="window.open(this.to); return false;">
              Commencer
            </NavLink>
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <img className="w-100" src="./assets/img/carousel-1.jpg" alt="slider-2" title='image' />
                <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                  <div className="p-3" style={{ maxWidth: 900 }}>
                    <h1 className="display-1 text-white mb-md-4 animated zoomIn" style={{fontFamily:"Zilla Slab",letterSpacing:5}}>Entreprendre, c'est partager.</h1>
                    <h5 className="text-white text-uppercase mb-3 animated slideInDown">Icstartup accompagne les startups dans le développement de leur visibilité.</h5>
                    <NavLink to="./Devis" className="btn btn-lg btn-primary rounded"  rel = "noreferrer" style={{width:130,marginLeft:-65}}>
              Commencer
              
            </NavLink>
           
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <img className="w-100" src="./assets/img/carousel-1.jpg" alt="slider-3" title='image' />
                <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                  <div className="p-3" style={{ maxWidth: 900 }}>
                    <h1 className="display-1 text-white mb-md-4 animated zoomIn"style={{fontFamily:"Zilla Slab",letterSpacing:5}}>Diffuser, c'est partager.</h1>
                    <h5 className="text-white text-uppercase mb-3 animated slideInDown">Icstartup démultiplie la visibilité des messages sur les réseaux sociaux.</h5>
                    <NavLink to="./Devis" className="btn btn-lg btn-primary rounded"  rel = "noreferrer" style={{width:130,marginLeft:-65}}>
              Commencer
            </NavLink>
                  </div>
                </div>
              </div>
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#header-carousel" data-bs-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true" />
              <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#header-carousel" data-bs-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true" />
              <span className="visually-hidden">Next</span>
            </button>
          </div>

          {/* Navbar & Carousel End */}
          {/* Full Screen Search Start */}
          <div className="modal fade" id="searchModal" tabIndex={-1}>
            <div className="modal-dialog modal-fullscreen">
              <div className="modal-content" style={{ background: 'rgba(9, 30, 62, .7)' }}>
                <div className="modal-header border-0">
                  <button type="button" className="btn bg-white btn-close" data-bs-dismiss="modal" aria-label="Close" />
                </div>
                <div className="modal-body d-flex align-items-center justify-content-center">
                  <div className="input-group" style={{ maxWidth: 600 }}>
                    <input type="text" className="form-control bg-transparent border-primary p-3" placeholder="Type search keyword" />
                    <button className="btn btn-primary px-4"><i className="bi bi-search" /></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Full Screen Search End */}
          {/* Facts Start */}
          <div className="container-fluid facts py-5 pt-lg-0">
            <div className="container py-5 pt-lg-0">
              <div className="row gx-0">
                <div className="col-lg-4 wow zoomIn" data-wow-delay="0.9s">
                  <div className="bg-primary shadow d-flex align-items-center justify-content-center p-4" style={{ height: 150 }}>
                    <div className="bg-white d-flex align-items-center justify-content-center rounded mb-2" style={{ width: 60, height: 60 }}>
                      <i className="fa fa-users text-primary" />
                    </div>
                    <div className="ps-4">
                      <h5 className="text-white mb-0"> Clients satisfaits</h5>
                      <h1 className="text-white mb-0" data-toggle="counter-up">320</h1>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 wow zoomIn" data-wow-delay="0.9s">
                  <div className="bg-light shadow d-flex align-items-center justify-content-center p-4" style={{ height: 150 }}>
                    <div className="bg-primary d-flex align-items-center justify-content-center rounded mb-2" style={{ width: 60, height: 60 }}>
                      <i className="fa fa-check text-white" />
                    </div>
                    <div className="ps-4">
                      <h5 className="text-primary mb-0">Projets réalisés</h5>
                      <h1 className="mb-0" data-toggle="counter-up">830</h1>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 wow zoomIn" data-wow-delay="0.9s">
                  <div className="bg-primary shadow d-flex align-items-center justify-content-center p-4" style={{ height: 150 }}>
                    <div className="bg-white d-flex align-items-center justify-content-center rounded mb-2" style={{ width: 60, height: 60 }}>
                      <i className="fa fa-award text-primary" />
                    </div>
                    <div className="ps-4">
                      <h5 className="text-white mb-0">Startups accompagnées</h5>
                      <h1 className="text-white mb-0" data-toggle="counter-up">55</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Facts Start */}
          {/* About Start */}
          <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
            <div className="container py-5">
              <div className="row g-5">
                <div className="col-lg-7">
                  <div className="section-title position-relative pb-3 mb-5">
                    <h5 className="fw-bold text-primary text-uppercase">À propos de nous</h5>
                    <h1 className="mb-0">Icstartup</h1>
                  </div>
                  <p className="mb-4">Nous sommes  Icstartup une agence conseil en communication et stratégie de marque, basée à Béja et Tunis.
                   </p>
                  <Link to="./About" className="btn btn-primary py-3 px-5 mt-3 wow zoomIn" data-wow-delay="0.9s">Lire plus</Link>
                </div>
                <div className="col-lg-5" style={{ minHeight: 500 }}>
                  <div className="position-relative h-100">
                    <img className="position-absolute w-100 h-100 rounded wow zoomIn" data-wow-delay="0.9s" src="./assets/img/Pacman-removebg-preview.png" style={{ objectFit: 'cover' }} alt='imagem' title='imagem' />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* About End */}
          {/* Features Start */}
          <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
            <div className="container py-5">
              <div className="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{ maxWidth: 600 }}>
                <h5 className="fw-bold text-primary text-uppercase">POURQUOI NOUS CHOISIR</h5>
                <h1 className="mb-0">Nous sommes là pour faire croître votre entreprise de façon exponentielle</h1>
              </div>
              <div className="row g-5">
                <div className="col-lg-4">
                  <div className="row g-5">
                    <div className="col-12 wow zoomIn" data-wow-delay="0.2s">
                      <div className="bg-primary rounded d-flex align-items-center justify-content-center mb-3" style={{ width: 60, height: 60 }}>
                        <i className="fa fa-cubes text-white" />
                      </div>
                      <h4>Accompagnement digital</h4>

                    </div>
                    <div className="col-12 wow zoomIn" data-wow-delay="0.6s">
                      <div className="bg-primary rounded d-flex align-items-center justify-content-center mb-3" style={{ width: 60, height: 60 }}>
                        <i className="fa fa-award text-white" />
                      </div>
                      <h4>Formations Sur mesure</h4>

                    </div>
                  </div>
                </div>
                <div className="col-lg-4  wow zoomIn" data-wow-delay="0.9s" style={{ minHeight: 350 }}>
                  <div className="position-relative h-100">
                    <img className="position-absolute w-100 h-100 rounded wow zoomIn" data-wow-delay="0.1s" src="./assets/img/feature.jpg" style={{ objectFit: 'cover' }} alt='imagem' title='imagem' />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="row g-5">
                    <div className="col-12 wow zoomIn" data-wow-delay="0.4s">
                      <div className="bg-primary rounded d-flex align-items-center justify-content-center mb-3" style={{ width: 60, height: 60 }}>
                        <i className="fa fa-users-cog text-white" />
                      </div>
                      <h4>Une Equipe de Professionnels</h4>

                    </div>
                    <div className="col-12 wow zoomIn" data-wow-delay="0.8s">
                      <div className="bg-primary rounded d-flex align-items-center justify-content-center mb-3" style={{ width: 60, height: 60 }}>
                        <i className="fa fa-phone-alt text-white" />
                      </div>
                      <h4>Conseil & Consulting</h4>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Features Start */}
          {/* Service Start */}
          <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
            <div className="container py-5">
              <div className="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{ maxWidth: 600 }}>
                <h5 className="fw-bold text-primary text-uppercase">Nos Services</h5>
                <h1 className="mb-0">Des solutions informatiques personnalisées pour votre entreprise prospère</h1>
              </div>
              <div className="row g-5">
                <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.9s">
                  <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                    <img src="./assets/img/pacmen.png" style={{ width: 100 }} alt="" />
                    <h4 className="mb-3" style={{ fontSize: 18 }}>DÉVELOPPEMENT WEB</h4>
                    <p className="m-0"> Développez votre site avec notre agence <br /><br /><br /><br /></p>
                    <Link to="./Developpementweb" className="btn btn-lg btn-primary rounded" >
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.9s">
                  <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                    <img src="./assets/img/pacmen.png" style={{ width: 100 }} alt="" />
                    <h4 className="mb-3" style={{ fontSize: 18 ,fontWeight:'normal'}}>DÉVELOPPEMENT MOBILE</h4>
                    <p className="m-0"> Développez votre application mobile avec notre agence<br /><br /><br /><br /></p>
                    <Link to="./Developpementmobile" className="btn btn-lg btn-primary rounded" >
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.9s">
                  <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                    <img src="./assets/img/pacmen.png" style={{ width: 100 , marginTop:30 }} alt="" />
                    <h4 className="mb-3" style={{ fontSize: 18 ,fontWeight:'normal' }}>DÉVELOPPEMENT LOGICIEL</h4>
                    <p className="m-0">Un logiciel sur-mesure est une application développée selon vos besoins, spécifiquement pour votre activité. <br /><br /><br /><br /></p>
                    <Link to="./Developpementlogiciel" className="btn btn-lg btn-primary rounded" >
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                  <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                    <img src="./assets/img/pacmen.png" style={{ width: 100 }} alt="" />
                    <h4 className="mb-3" style={{ fontSize: 18 ,fontWeight:'normal'}}>DESIGN GRAPHIQUE </h4>
                    <p className="m-0">Les bases d’une communication réussie d’une entreprise résident dans le pouvoir attractif de son identité visuelle.</p>
                    <Link to="./Design" className="btn btn-lg btn-primary rounded">
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
                  <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                    <img src="./assets/img/pacmen.png" style={{ width: 100 ,marginTop:70}} alt="" />
                    <h4 className="mb-3" style={{ fontSize: 18 }}>MARKETING DIGITAL</h4>
                    <p className="m-0">De nos jours, le web s’invite à tous les niveaux ! Communication, stratégie, développement de l’entreprise, plan d’action, campagnes de publicité.<br /><br /><br /><br /></p>
                    <Link to="./Marketingdigital" className="btn btn-lg btn-primary rounded">
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
                  <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                    <img src="./assets/img/pacmen.png" style={{ width: 100 }} alt="" />
                    <h4 className="mb-3" style={{ fontSize: 18 }}> PRODUCTION AUDIOVISUELLE </h4>
                    <p className="m-0">Photographie / Production vidéo<br /><br /><br /><br /></p>
                    <Link to="./Audiovisuelle" className="btn btn-lg btn-primary rounded">
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
                  <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                    <img src="./assets/img/pacmen.png" style={{ width: 100 }} alt="" />
                    <h4 className="mb-3" style={{ fontSize: 18 }}>FORMATION TECHNOLOGIQUE</h4>
                    <p className="m-0">Cabinet de coaching et d'accompagnement offrant diverses formes de services aux professionnels et aux particuliers.<br /><br /><br /><br /></p>
                    <Link to="./Formationtechnologique" className="btn btn-lg btn-primary rounded">
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
                  <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                    <img src="./assets/img/pacmen.png" style={{ width: 100 }} alt="" />
                    <h4 className="mb-3" style={{ fontSize: 18 }}>INCUBATION </h4>
                    <p className="m-0">la concrétisation de votre projet en entreprise, de la création au démarrage commercial<br /><br /><br /><br /></p>
                    <Link to="./Incubation" className="btn btn-lg btn-primary rounded">
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
                  <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                    <img src="./assets/img/pacmen.png" style={{ width: 100 }} alt="" />
                    <h4 className="mb-3" style={{ fontSize: 18 }}>CONSEIL &amp; CONSULTING </h4>
                    <p className="m-0">une prestation de services intellectuels · mission ponctuelle ou durable,<br /><br /><br /><br /></p>
                    <Link to="./Conseilconsulting" className="btn btn-lg btn-primary rounded">
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Service End */}
          {/* Testimonial Start */}


          <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
            <div className="container py-5">
              <div className="section-title text-center position-relative pb-3 mb-4 mx-auto" style={{ maxWidth: 600 }}>
                <h5 className="fw-bold text-primary text-uppercase">Testimonial</h5>
                <h1 className="mb-0">Ce que nos clients disent de nos services numériques</h1>
              </div>

              <OwlCarousel items="1" autoplay>
                <div className="testimonial-item bg-light my-4" style={{ width: 580, marginLeft: 260 }}>
                  <div className="d-flex align-items-center border-bottom pt-5 pb-4 px-5">
                    <img className="img-fluid rounded" src="./assets/img/tacapes.jfif" style={{ width: 60, height: 60 }} alt='Tacapes ' title='Tacapes ' />
                    <div className="ps-4">
                      <h4 className="text-primary mb-1">Tacapes Tours Tunisia</h4>
                      <small className="text-uppercase">Agence de voyage</small>
                    </div>
                  </div>
                  <div className="pt-4 pb-5 px-5">
                    Une équipe soudée, bien rodée et dirigée par un Boss de grand calibre.
                  </div>
                </div>

                <div className="testimonial-item bg-light my-4" style={{ width: 580, marginLeft: 260 }}>
                  <div className="d-flex align-items-center border-bottom pt-5 pb-4 px-5">
                    <img className="img-fluid rounded" src="./assets/img/boukhatem.png" style={{ width: 60, height: 60 }} alt='boukhatem' title='boukhatem' />
                    <div className="ps-4">
                      <h4 className="text-primary mb-1">boukhatem sofien</h4>
                      <small className="text-uppercase">Formateur</small>
                    </div>
                  </div>
                  <div className="pt-4 pb-5 px-5">
                    Service excellent et professionnel, un grand Merci à Bilel Amdouni
                  </div>
                </div>

                <div className="testimonial-item bg-light my-4" style={{ width: 580, marginLeft: 260 }}>

                  <div className="d-flex align-items-center border-bottom pt-5 pb-4 px-5">
                    <img className="img-fluid rounded" src="./assets/img/aymen_haboubi.jpg" style={{ width: 60, height: 60 }} alt='aymen_haboubi' title='aymen_haboubi' />
                    <div className="ps-4">boukhatem
                      <h4 className="text-primary mb-1">Aymen Haboubi</h4>
                      <small className="text-uppercase">Formateur</small>
                    </div>
                  </div>
                  <div className="pt-4 pb-5 px-5">
                    Trés professionnel et une excellente qualité de service.
                  </div>
                </div>



              </OwlCarousel>
            </div>
          </div>


          {/* Testimonial End */}
          {/* Team Start */}

          <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
            <div className="container py-5">
              <div className="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{ maxWidth: 600 }}>
                <h5 className="fw-bold text-primary text-uppercase">Membres de l'équipes</h5>
                <h1 className="mb-0">Equipes professionnels prêts à aider votre entreprise</h1>
              </div>
              <div className="row g-5">
              <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
                  <div className="team-item bg-light rounded overflow-hidden">
                    <div className="team-img position-relative overflow-hidden">
                      <img className="img-fluid w-100" src="./assets/img/m3-team-2.png" alt="" />
                      <div className="team-social">
                       
                      <a href="https://www.linkedin.com/in/imen-jabri-340b58190/" className="btn btn-lg btn-primary btn-lg-square rounded" target = "_blank" rel='noreferrer'><i className="fab fa-linkedin-in fw-normal" /></a>
                      </div>
                    </div>
                    <div className="text-center py-4">
                      <h4 className="text-primary">Imen Jabri</h4>
                      <p className="text-uppercase m-0">CEO</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
                  <div className="team-item bg-light rounded overflow-hidden">
                    <div className="team-img position-relative overflow-hidden">
                      <img className="img-fluid w-100" src="./assets/img/m3-team-1.png" alt='imagem' title='imagem' />
                      <div className="team-social">
                        
                      <a href="https://www.linkedin.com/in/bilel-amdouni-075b6617b/" className="btn btn-lg btn-primary btn-lg-square rounded" target = "_blank" rel='noreferrer'><i className="fab fa-linkedin-in fw-normal" /></a>
                      </div>
                    </div>
                    <div className="text-center py-4">
                      <h4 className="text-primary">Bilel Amdouni</h4>
                      <p className="text-uppercase m-0">CTO-COFOUNDER</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 wow slideInUp" data-wow-delay="0.9s">
                  <div className="team-item bg-light rounded overflow-hidden">
                    <div className="team-img position-relative overflow-hidden">
                      <img className="img-fluid w-100" src="./assets/img/m3-team-1.png" alt='imagem' title='imagem' />
                      <div className="team-social">
                       
                      <a href="https://www.linkedin.com/in/mohamed-ali-belajouza/" className="btn btn-lg btn-primary btn-lg-square rounded" target = "_blank" rel='noreferrer'><i className="fab fa-linkedin-in fw-normal" /></a>
                      </div>
                    </div>
                    <div className="text-center py-4">
                      <h4 className="text-primary">Dali Belajouza</h4>
                      <p className="text-uppercase m-0">CBO</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Team End */}
          {/* Vendor Start */}
          <div className="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{ maxWidth: 600 }}>
            <h5 className="fw-bold text-primary text-uppercase">Nos Partenaires</h5>
            <h1 className="mb-0">Les Partenaires officieles du l'agence</h1>
           </div>
            <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
 

            <div className="container py-5 mb-5">
              <div className="bg-white">
                <OwlCarousel items="1" autoplay >

                  <img src="./assets/img/partenaire/paretenaires1.png" alt='Digitaltransformation' title='Digitaltransformation' />
                 
                  
                </OwlCarousel>


              </div>
            </div>

          </div>



          <div className="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{ maxWidth: 600 }}>
            <h5 className="fw-bold text-primary text-uppercase">Nos Clients</h5>
            <h1 className="mb-0">Les clients officieles du l'agence</h1>
           </div>
            <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">

            <div className="container py-5 mb-5">
              <div className="bg-white">
                <OwlCarousel items="1"  autoplay>

                <img src="./assets/img/partenaire/paretenaires2.png" alt='dot' title='dot' />
                  <img src="./assets/img/partenaire/paretenaires3.png" alt='imagem' title='imagem' />
                  <img src="./assets/img/partenaire/paretenaires4.png" alt='imagem' title='imagem' />
                  <img src="./assets/img/partenaire/paretenaires5.png" alt='imagem' title='imagem' />
                  <img src="./assets/img/partenaire/paretenaires6.png" alt='imagem' title='imagem' />
                  <img src="./assets/img/partenaire/paretenaires7.png" alt='imagem' title='imagem' />
                  <img src="./assets/img/clients/client1.png" alt='imagem' title='imagem'style={{height:182,marginTop:-40}} />
                  <img src="./assets/img/clients/client2.png" alt='imagem' title='imagem' style={{height:200,marginTop:-40}}/>
             
             

                


                </OwlCarousel>


              </div>
            </div>

          </div>

        </div>




      </div>
    );
  }
}
export default Home