import { useRef } from 'react'; 
import emailjs from 'emailjs-com';
import swal from 'sweetalert';
import{Link} from 'react-router-dom';


export default function Contact() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_agugum3', 'template_9mu98md', form.current, '0lWVIqTi320ovPKAL')
      .then((result) => {
          
        swal("seccessful !...", "Votre message a été envoyé !", "success");
      }, (error) => {
          swal("Error!...", "Vous avez une erreur de saisie !", "error");
      });
  };
      
        return (

            <div>
         <div className="container-fluid bg-primary py-5 bg-header" style={{marginBottom: 90}}>
  <div className="row py-5">
    <div className="col-12 pt-lg-5 mt-lg-5 text-center">
      <h1 className="display-4 text-white animated zoomIn">Contact </h1>
      <Link to="/" className="h5 text-white">Accueil</Link>
      <i className="far fa-circle text-white px-2" />
      <Link to="" className="h5 text-white">Contact</Link>
    </div>
  </div>
</div>


    <div>
    <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
      <div className="container py-5">
        <div className="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{maxWidth: 600}}>
          <h5 className="fw-bold text-primary text-uppercase">Contact</h5>
          <h1 className="mb-0">Si vous avez des questions, n'hésitez pas à nous contacter</h1>
        </div>
        <div className="row g-5 mb-5">
          <div className="col-lg-4">
            <div className="d-flex align-items-center wow fadeIn" data-wow-delay="0.1s">
              <div className="bg-primary d-flex align-items-center justify-content-center rounded" style={{width: 60, height: 60}}>
                <i className="fa fa-phone-alt text-white" />
              </div>
              <div className="ps-4">
                <h5 className="mb-2">Appelez pour poser n'importe quelle question</h5>
                <h4 className="text-primary mb-0">+216 97 732 156</h4>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="d-flex align-items-center wow fadeIn" data-wow-delay="0.4s">
              <div className="bg-primary d-flex align-items-center justify-content-center rounded" style={{width: 60, height: 60}}>
                <i className="fa fa-envelope-open text-white" />
              </div>
              <div className="ps-4">
                <h5 className="mb-2">E-mail pour obtenir un devis gratuit</h5>
                <h4 className="text-primary mb-0">hello@icstartup.digital</h4>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="d-flex align-items-center wow fadeIn" data-wow-delay="0.8s">
              <div className="bg-primary d-flex align-items-center justify-content-center rounded" style={{width: 60, height: 60}}>
                <i className="fa fa-map-marker-alt text-white" />
              </div>
              <div className="ps-4">
                <h5 className="mb-2">Visitez notre bureau</h5>
                <h4 className="text-primary mb-0">Rue 18 Janvier 9000 Béja</h4>
              </div>
            </div>
          </div>
        </div>
        <div className="row g-5">
          <div className="col-lg-6 wow slideInUp" data-wow-delay="0.3s">
            <form ref={form} onSubmit={sendEmail} method="POST">
              <div className="row g-3">
                <div className="col-md-6">
                  <input type="text" name="from_name" className="form-control border-0 bg-light px-4" placeholder="Nom ey Prénom" style={{height: 55}} required />
                </div>
                <div className="col-md-6">
                  <input type="email" name="from_email"  className="form-control border-0 bg-light px-4" placeholder="Votre Email" style={{height: 55}} required />
                </div>
                <div className="col-12">
                  <input type="text" name="from_objet" className="form-control border-0 bg-light px-4" placeholder="Objet" style={{height: 55}} required />
                </div>
                <div className="col-12">
                  <textarea name="message"  className="form-control border-0 bg-light px-4 py-3" rows={4} placeholder="Message" required defaultValue={""} />
                </div>
             
            
                <div className="col-12">
                  <button className="btn btn-primary w-100 py-3" type="submit">Envoyer Message</button>
                </div>
              </div>
            </form>
          </div>
          <div className="col-lg-6 wow slideInUp" data-wow-delay="0.6s">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3197.742474171463!2d9.181321914777442!3d36.72874567941935!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12fb5994a245e70d%3A0xb22732646433bd4!2sINTERNATIONAL%20COACH%20START-UP!5e0!3m2!1sfr!2stn!4v1649411460710!5m2!1sfr!2stn" width={600} height={450} style={{border: 0}} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade"title="map" />
          </div>
        </div>
      </div>
    </div>
    {/* Contact End */}
   
  </div>


  </div>
        );
  }
