import React from 'react'
import{Link} from 'react-router-dom';

class Developpementmobile extends React.Component {

  render() {
    return (
        <div>
                <div className="container-fluid bg-primary py-5 bg-header" style={{marginBottom: 90}}>
                  <div className="row py-5">
                    <div className="col-12 pt-lg-5 mt-lg-5 text-center">
                      <h1 className="display-4 text-white animated zoomIn">Developpement Mobile</h1>
                      <Link to="/"  className="h5 text-white">Accueil</Link>
                      <i className="far fa-circle text-white px-2" />
                      <Link to=""  className="h5 text-white">Developpement Mobile</Link>
                    </div>
                  </div>
                </div>
            


                <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
                <div className="container py-5">
                  <div className="row g-5">
                    <div className="col-lg-7">
                      <div className="section-title position-relative pb-3 mb-5">
                        
                        <h1 className="mb-0">Developpement Mobile</h1>
                      </div>
                      <p className="mb-4">AppStore, PlayStore, PhoneStore, le déploiement d’une application mobile peut être un vrai casse-tête. ICSTARTUP vous accompagne dans la conception et réalisation de vos applications en vous aidant à choisir votre stratégie de déploiement tout en minimisant les coûts grâce aux technologies hybrides. Notre équipe a pour but de vous créer une liste des meilleures agences en application mobile pour vos besoins..</p>
                     
                    </div>
                    <div className="col-lg-5" style={{minHeight: 500}}>
                      <div className="position-relative h-100">
                        <img className="position-absolute w-100 h-100 rounded wow zoomIn" data-wow-delay="0.9s" src="./assets/img/devmobile.webp" style={{objectFit: 'cover'}} alt='imagem' title='imagem'/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>




              <div className="row g-5">
          <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.9s">
            <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
              {/* <div className="service-icon">
              <i className="fa fa-code text-white" />
            </div> */}
              <h4 className="mb-3" style={{ fontSize: 18 }}>Géolocalisation Mobile</h4>
              <img src="./assets/img/mobile1.png" style={{width:200}} alt='imagem' title='imagem' />

            </div>
          </div>
          <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.9s">
            <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
              {/* <div className="service-icon">
              <i className="fa fa-code text-white" />
            </div> */}
              <h4 className="mb-3" style={{ fontSize: 18 }}>Notification Mobile</h4>
              <img src="./assets/img/mobile2.png" style={{width:200}} alt='imagem' title='imagem' />

            </div>
          </div>

          <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.9s">
            <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
              {/* <div className="service-icon">
              <i className="fa fa-code text-white" />
            </div> */}
              <h4 className="mb-3" style={{ fontSize: 18 }}>Paiement Mobile & API SMS Mobile</h4>
              <img src="./assets/img/mobile3.png" style={{width:200}} alt='imagem' title='imagem' />

            </div>
          </div>



        </div>




        </div>
    );
  }
}
export default Developpementmobile