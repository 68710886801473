import React from 'react'
import{Link} from 'react-router-dom';

class Audiovisuelle extends React.Component {

  render() {
    return (
        <div>
                <div className="container-fluid bg-primary py-5 bg-header" style={{marginBottom: 90}}>
                  <div className="row py-5">
                    <div className="col-12 pt-lg-5 mt-lg-5 text-center">
                      <h1 className="display-4 text-white animated zoomIn">Production Audiovisuelle</h1>
                      <Link to="/"  className="h5 text-white">Accueil</Link>
                      <i className="far fa-circle text-white px-2" />
                      <Link to=""  className="h5 text-white">Production Audiovisuelle</Link>
                    </div>
                  </div>
                </div>
            




                <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
                <div className="container py-5">
                  <div className="row g-5">
                    <div className="col-lg-7">
                      <div className="section-title position-relative pb-3 mb-5">
                        
                        <h1 className="mb-0">Production Audiovisuelle</h1>
                      </div>
                      <h5>Photographie</h5>
                      <p className="mb-4">Aujourd’hui, les images sont considérées comme une source d’information et d’émotions de plus en plus importante. Il est donc essentiel de bien présenter votre entreprise, votre produit ou service au moyen d’images professionnelles. Grâce à notre vaste équipement photographique et à notre studio photo, nous sommes bien préparés pour créer vos photos à la fois en studio et sur place. Photographie commerciale. Nous prenons soin de photographier votre entreprise, vos employés, vos locaux ou vos produits de manière professionnelle, afin que vous puissiez utiliser le matériel photographique à des fins publicitaires supplémentaires (site web, matériel imprimé, publicités, …).

                                  * Photographie pour les entreprises

                                  * Shooting des produits sur fond blanc (Packshots)

                                  * Photographie d’évènementiel

                                  * Séances de photos pour les particuliers

                                  * Nos services pour les particuliers et les organisations

                                  * Production vidéo</p>
                      <h5>La production vidéo</h5>
                      <p className="mb-4">On vous offre un service complet de production médiatique qui se concentre sur la création de films magnifiquement esthétiques qui créent une impression durable avec un contenu exceptionnel quel que soit le projet: Shorts vidéo, vidéos clips, films promotionnels, films institutionnels, publicités, vidéos adaptées aux réseaux sociaux. Notre équipe d’experts est expérimentée dans tous les aspects de la production vidéo et peut faire le nécessaire pour votre projet. Nous offrons tout ce dont vous avez besoin pour créer une vidéo attrayante et originale qui renforce votre message et présente vos valeurs.</p>

                    </div>
                    <div className="col-lg-5" style={{minHeight: 500}}>
                      <div className="position-relative h-100">
                        <img className="position-absolute w-100 h-100 rounded wow zoomIn" data-wow-delay="0.9s" src="./assets/img/audio.webp" style={{objectFit: 'cover'}} alt='imagem' title='imagem'/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


        </div>
    );
  }
}
export default Audiovisuelle