import React from 'react'
import{Link} from 'react-router-dom';

class Developpementlogiciel extends React.Component {

  render() {
    return (
        <div>
                <div className="container-fluid bg-primary py-5 bg-header" style={{marginBottom: 90}}>
                  <div className="row py-5">
                    <div className="col-12 pt-lg-5 mt-lg-5 text-center">
                      <h1 className="display-4 text-white animated zoomIn">Developpement Logiciel</h1>
                      <Link to="/"  className="h5 text-white">Accueil</Link>
                      <i className="far fa-circle text-white px-2" />
                      <Link to=""  className="h5 text-white">Developpement Logiciel</Link>
                    </div>
                  </div>
                </div>
            



                <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
                <div className="container py-5">
                  <div className="row g-5">
                    <div className="col-lg-7">
                      <div className="section-title position-relative pb-3 mb-5">
                        
                        <h1 className="mb-0">Developpement Logiciel</h1>
                      </div>
                      <p className="mb-4">Un logiciel sur-mesure est une application développée selon vos besoins, spécifiquement pour votre activité. ICSTARTUP possède une véritable expertise dans le développement de logiciels sur mesure “web”, c’est-à-dire accessible de façon sécurisée depuis votre navigateur Internet.</p>
                     
                    </div>
                    <div className="col-lg-5" style={{minHeight: 500}}>
                      <div className="position-relative h-100">
                        <img className="position-absolute w-100 h-100 rounded wow zoomIn" data-wow-delay="0.9s" src="./assets/img/logiciel.png" style={{objectFit: 'cover'}} alt='imagem' title='imagem'/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


        </div>
    );
  }
}
export default Developpementlogiciel