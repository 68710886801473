import React from 'react'
import { Link } from 'react-router-dom';

class Developpementweb extends React.Component {

  render() {
    return (
      <div>
        <div className="container-fluid bg-primary py-5 bg-header" style={{ marginBottom: 90 }}>
          <div className="row py-5">
            <div className="col-12 pt-lg-5 mt-lg-5 text-center">
              <h1 className="display-4 text-white animated zoomIn">Developpement web</h1>
              <Link to="/" className="h5 text-white">Accueil</Link>
              <i className="far fa-circle text-white px-2" />
              <Link to="" className="h5 text-white">Developpement web</Link>
            </div>
          </div>
        </div>




        <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
          <div className="container py-5">
            <div className="row g-5">
              <div className="col-lg-7">
                <div className="section-title position-relative pb-3 mb-5">

                  <h1 className="mb-0">Developpement web</h1>
                </div>
                <p className="mb-4">Les bases d’une communication réussie d’une entreprise résident dans le pouvoir attractif de son identité visuelle. L’image graphique est le meilleur moyen Marketing pour capturer l’attention. Cette tache doit être, de préférence, confiée à une agence de communication dynamique spécialisée afin d’avoir les meilleurs résultats possibles. Chez ICSTARTUP, nous vous aidons à faire une image d’étoile pour briller dans le ciel de votre marché. Nous mettons à votre service notre créativité pour des conceptions originales, aux normes de vos exigences.</p>

              </div>
              <div className="col-lg-5" style={{ minHeight: 500 }}>
                <div className="position-relative h-100">
                  <img className="position-absolute w-100 h-100 rounded wow zoomIn" data-wow-delay="0.9s" src="./assets/img/devweb.jpg" style={{ objectFit: 'cover' }} alt='imagem' title='imagem' />
                </div>
              </div>
            </div>
          </div>
        </div>




        <div className="row g-5">
          <div className="col-lg-3 col-md-6 wow zoomIn" data-wow-delay="0.9s">
            <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
              {/* <div className="service-icon">
              <i className="fa fa-code text-white" />
            </div> */}
              <h4 className="mb-3" style={{ fontSize: 18 }}>Site Vitrine</h4>
              <p className="m-0">Choisir la solution “site vitrine” c’est vous assurer d’être présent sur le web rapidement par la réalisation d’un site internet simple mais utile et efficace.<br /><br /><br /><br /></p>
              <Link to="./Devis" className="btn btn-lg btn-primary rounded"  style={{width:130,marginLeft:-65}}>
              Demander devis
            </Link>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 wow zoomIn" data-wow-delay="0.9s">
            <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
              {/* <div className="service-icon">
              <i className="fa fa-code text-white" />
            </div> */}
              <h4 className="mb-3" style={{ fontSize: 18 }}>Site Dynamique</h4>
              <p className="m-0">Créer un site web dynamique est un pas capital pour les entreprises qui désirent évoluer et se digitaliser de plus en plus. Il est temps de vous lancer ! <br /><br /><br /><br /></p>
              <Link to="./Devis" className="btn btn-lg btn-primary rounded"  style={{width:130,marginLeft:-65}}>
              Demander devis
            </Link>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 wow zoomIn" data-wow-delay="0.9s">
            <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
              {/* <div className="service-icon">
              <i className="fa fa-code text-white" />
            </div> */}
              <h4 className="mb-3" style={{ fontSize: 18 }}>Site e-commerce</h4>
              <p className="m-0">Grâce à notre solution e-commerce vous pouvez créer une boutique en ligne et vendre sur internet afin d’augmenter vos ventes et gagner des nouveaux clients. <br /><br /><br /><br /></p>
              <Link to="./Devis" className="btn btn-lg btn-primary rounded"  style={{width:130,marginLeft:-65}}>
              Demander devis
            </Link>
            </div>
          </div>


          <div className="col-lg-3 col-md-6 wow zoomIn" data-wow-delay="0.9s">
            <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
              {/* <div className="service-icon">
              <i className="fa fa-code text-white" />
            </div> */}
              <h4 className="mb-3" style={{ fontSize: 18 }}>Refonte site web </h4>
              <p className="m-0">La refonte d'un site peut revêtir des aspects bien différents, selon qu'il s'agisse d'une simple refonte graphique ne remettant pas en cause l'arborescence du site. <br /><br /><br /><br /></p>
              <Link to="./Devis" className="btn btn-lg btn-primary rounded"  style={{width:130,marginLeft:-65}}>
              Demander devis
            </Link>
            </div>
          </div>



        </div>



      </div>
    );
  }
}
export default Developpementweb