import React from 'react'
import{Link} from 'react-router-dom';

class Conseilconsulting extends React.Component {

  render() {
    return (
        <div>
                <div className="container-fluid bg-primary py-5 bg-header" style={{marginBottom: 90}}>
                  <div className="row py-5">
                    <div className="col-12 pt-lg-5 mt-lg-5 text-center">
                      <h1 className="display-4 text-white animated zoomIn">Conseil &amp; Consulting</h1>
                      <Link to=""  className="h5 text-white">Accueil</Link>
                      <i className="far fa-circle text-white px-2" />
                      <Link to=""  className="h5 text-white">Conseil &amp; Consulting</Link>
                    </div>
                  </div>
                </div>
            

                <div className="row g-5">
          <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.9s">
            <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
              {/* <div className="service-icon">
              <i className="fa fa-code text-white" />
            </div> */}
              <h4 className="mb-3" style={{ fontSize: 18 }}>Management</h4>
              <p className="m-0">Pour développer vos capacités managériales et vous préparer à devenir un BON MANAGER, nous nous chargerons d’organiser des séances de formation sur les deux volets du management à savoir le stratégique et l'opérationnel. Nous vous assurons également un accompagnement pour réussir le lancement et le développement de votre entreprise.<br /><br /><br /><br /></p>
              <Link to="./Devis" className="btn btn-lg btn-primary rounded"  style={{width:130,marginLeft:-65}}>
              Demander devis
            </Link>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.9s">
            <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
              {/* <div className="service-icon">
              <i className="fa fa-code text-white" />
            </div> */}
              <h4 className="mb-3" style={{ fontSize: 18 }}>Soft Skills</h4>
              <p className="m-0">Cultiver les soft skills de vos employés est nécessaire pour éviter les conflits et créer une meilleure ambiance de travail. C'est dans cette perspective qu'intervient le cabinet de formation MedSirat en mettant à votre service les formations nécessaires au développement du capital humain de votre business et en vous assurant un accompagnement spécifique et adapté à votre structure. <br /><br /><br /><br /></p>
              <Link to="./Devis" className="btn btn-lg btn-primary rounded"  style={{width:130,marginLeft:-65}}>
              Demander devis
            </Link>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.9s">
            <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
              {/* <div className="service-icon">
              <i className="fa fa-code text-white" />
            </div> */}
              <h4 className="mb-" style={{ fontSize: 18 }}>Digital et IT</h4>
              <p className="m-0">Il est primordial d'être toujours en phase avec l’évolution rapide des nouvelles technologies, la nouvelle économie électronique, les technologies de l’information et de la communication … Pour ce faire, nous vous proposons de mettre à votre service une panoplie de formations qui répondront à vos besoins professionnels et nous vous offrons un accompagnement dans vos projets IT. <br /><br /><br /><br /></p>
              <Link to="./Devis" className="btn btn-lg btn-primary rounded"  style={{width:130,marginLeft:-65}}>
              Demander devis
            </Link>
            </div>
          </div>


        </div>







        </div>
    );
  }
}
export default Conseilconsulting