import React from 'react'
import{Link} from 'react-router-dom';

class Marketingdigital extends React.Component {

  render() {
    return (
        <div>
                <div className="container-fluid bg-primary py-5 bg-header" style={{marginBottom: 90}}>
                  <div className="row py-5">
                    <div className="col-12 pt-lg-5 mt-lg-5 text-center">
                      <h1 className="display-4 text-white animated zoomIn">Marketing Digital</h1>
                      <Link to="/Home"  className="h5 text-white">Accueil</Link>
                      <i className="far fa-circle text-white px-2" />
                      <Link to=""  className="h5 text-white">Marketing Digital</Link>
                    </div>
                  </div>
                </div>
            



                <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
          <div className="container py-5">
            <div className="row g-5">
              <div className="col-lg-7">
                <div className="section-title position-relative pb-3 mb-5">

                  <h1 className="mb-0">Marketing digital</h1>
                </div>
                <p className="mb-4">De nos jours, le web s’invite à tous les niveaux ! Communication, stratégie, développement de l’entreprise, plan d’action, campagnes de publicité. Il faut souligner que le digital marketing tend à favoriser la mise en place du marketing participatif, lequel amplifie les phénomènes de diffusion de l’information, tout en contribuant à transformer le bouche à oreille en marketing viral, contrôlé plus ou moins par les marques. En outre, le digital marketing a pour but d’augmenter les visites sur le site, de transformer les visites en actes d’achat et de fidéliser le client, et ce, en tissant une relation régulière avec lui.</p>

              </div>
              <div className="col-lg-5" style={{ minHeight: 500 }}>
                <div className="position-relative h-100">
                  <img className="position-absolute w-100 h-100 rounded wow zoomIn" data-wow-delay="0.9s" src="./assets/img/digital.webp" style={{ objectFit: 'cover' }} alt='imagem' title='imagem' />
                </div>
              </div>
            </div>
          </div>
        </div>







          <div className="row g-5">
          <div className="col-lg-3 col-md-6 wow zoomIn" data-wow-delay="0.9s">
            <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
              {/* <div className="service-icon">
              <i className="fa fa-code text-white" />
            </div> */}
              <h4 className="mb-3" style={{ fontSize: 18 }}>Référencement Natural</h4>
              <p className="m-0" style={{fontSize:13}}>La création d’un site internet est une étape indispensable pour votre stratégie digitale, mais elle n’est pas suffisante. Vous devez obligatoirement référencer votre site pour que ce dernier soit visible par les internautes. Le référencement naturel est une stratégie qui consiste à intégrer, sur les pages de votre site web, les tendances recherchées par votre cible dans votre région...<br /><br /><br /><br /></p>
              <Link to="./Devis" className="btn btn-lg btn-primary rounded"  style={{width:130,marginLeft:-65}}>
              Demander devis
            </Link>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 wow zoomIn" data-wow-delay="0.9s">
            <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
              {/* <div className="service-icon">
              <i className="fa fa-code text-white" />
            </div> */}
              <h4 className="mb-3" style={{ fontSize: 18 }}>Référencement Payant</h4>
              <p className="m-0"style={{fontSize:13}}>Le référencement payant permet à l’annonceur d’acquérir temporairement un positionnement idéal de son site internet, ayant de la sorte la possibilité de pouvoir tester ses campagnes à des coûts réduits. Pour toucher immédiatement une cible, promouvoir un nouveau site internet, mettre en avant ses activités, la pratique du référencement payant devient donc incontournable. <br /><br /><br /><br /></p>
              <Link to="./Devis" className="btn btn-lg btn-primary rounded"  style={{width:130,marginLeft:-65}}>
              Demander devis
            </Link>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 wow zoomIn" data-wow-delay="0.9s">
            <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
              {/* <div className="service-icon">
              <i className="fa fa-code text-white" />
            </div> */}
              <h4 className="mb-3" style={{ fontSize: 18 }}>Mobile marketing</h4>
              <p className="m-0" style={{fontSize:13}}>Le mobile marketing est en pleine expansion, d’autant plus que les applications, les sites internet mobiles optimisés ou encore le paiement mobile sont omniprésents dans notre quotidien. En effet, les marques savent pertinemment qu’une stratégie marketing efficace doit prendre en considération l’influence du smartphone et ses débouchés d’un point de vue marketing.... <br /><br /><br /><br /></p>
              <Link to="./Devis" className="btn btn-lg btn-primary rounded"  style={{width:130,marginLeft:-65}}>
              Demander devis
            </Link>
            </div>
          </div>


          <div className="col-lg-3 col-md-6 wow zoomIn" data-wow-delay="0.9s">
            <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
              {/* <div className="service-icon">
              <i className="fa fa-code text-white" />
            </div> */}
              <h4 className="mb-3" style={{ fontSize: 18 }}>Expertise Social Media Advertising</h4>
              <p className="m-0"style={{fontSize:13}}>Booster les ventes , Booster l’insrciption , Augmenter le trafic site web , Augmenter le trafic sur point de vente , SMA ,Ciblage audience , call to action , Ciblage démographique , Ciblage géographique , Préparation message publicitaire , Identification de l’objectif de la publicité , Publicité sur les réseaux sociaux , Retargeting , Social ads , Sponsoring Facebook , Sponsoring Instagram , Sponsoring LinkedIn ... <br /><br /><br /><br /></p>
              <Link to="./Devis" className="btn btn-lg btn-primary rounded"  style={{width:130,marginLeft:-65}}>
              Demander devis
            </Link>
            </div>
          </div>






          
          <div className="col-lg-3 col-md-6 wow zoomIn" data-wow-delay="0.9s">
            <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
              {/* <div className="service-icon">
              <i className="fa fa-code text-white" />
            </div> */}
              <h4 className="mb-3" style={{ fontSize: 18 }}>Email Marketing</h4>
              <p className="m-0" style={{fontSize:13}}>Pour ce faire, les spécialistes en stratégie digitale de notre agence emailing à Tunis élaboreront une stratégie marketing d’emailing ajustée à vos besoins et objectifs. Afin de concevoir une campagne d’email marketing des plus efficaces, nous travaillerons sur un design attrayant avec des visuels captivants, un contenu personnalisé et adapté contenant des renseignements de sources fiables et claires, puis nous assurerons également la gestion des envoies et du suivi.<br /><br /><br /><br /></p>
              <Link to="/Devis" className="btn btn-lg btn-primary rounded"  style={{width:130,marginLeft:-65}}>
              Demander devis
            </Link>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 wow zoomIn" data-wow-delay="0.9s">
            <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
              {/* <div className="service-icon">
              <i className="fa fa-code text-white" />
            </div> */}
              <h4 className="mb-3" style={{ fontSize: 18 }}>Expertise Référencement/SEO</h4>
              <p className="m-0"style={{fontSize:13}}>Référencement site web, Audit SEO, Optimisation Google , Etude Rentabilité et conversion web, Référencement vidéo , Etude sources de trafic , Onsite SEO , Offsite SEO , Backlinks , Arborescence site web , Statistiques détaillées , Optimisation SEO du contenu , Gestion Duplicate Content, référencement Application mobile , Google Analytics...<br /><br /><br /><br /></p>
              <Link to="/Devis" className="btn btn-lg btn-primary rounded"  style={{width:130,marginLeft:-65}}>
              Demander devis
            </Link>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 wow zoomIn" data-wow-delay="0.9s">
            <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
              {/* <div className="service-icon">
              <i className="fa fa-code text-white" />
            </div> */}
              <h4 className="mb-3" style={{ fontSize: 18 }}>Expertise Social Media Management</h4>
              <p className="m-0" style={{fontSize:13}}>Community Management : Facebook, Instagram, LinkedIn, Pinterest, Twitter, Youtube, Conversion en ventes, Analytics , Création page Facebook, gestion page Facebook, Jeux sur Facebook, Gestion compte Instagram ,Social Gaming, Stratégie Web to Store, Influenceurs ,instagrameurs, youtubeur, ROI, Social selling ... <br /><br /><br /><br /></p>
              <Link to="/Devis" className="btn btn-lg btn-primary rounded"  style={{width:130,marginLeft:-65}}>
              Demander devis
            </Link>
            </div>
          </div>


          <div className="col-lg-3 col-md-6 wow zoomIn" data-wow-delay="0.9s">
            <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
              {/* <div className="service-icon">
              <i className="fa fa-code text-white" />
            </div> */}
              <h4 className="mb-3" style={{ fontSize: 18 }}>Audit digitale et conseil</h4>
              <p className="m-0"style={{fontSize:13}}>Accompagnement à la transformation digitale, Etude Parcours client , Investissements digitaux , Recensement / diagnostic présence digitale , E-notoriété et E-réputation , Diagnostic réseaux sociaux, Diagnostic site web, Diagnostic applications mobile , Élaboration Stratégie digitale globale , Optimiser l’écosystème digital , Optimisation des coûts <br /><br /><br /><br /></p>
              <Link to="/Devis" className="btn btn-lg btn-primary rounded"  style={{width:130,marginLeft:-65}}>
              Demander devis
            </Link>
            </div>
          </div>





        

</div>
        </div>
    );
  }
}
export default Marketingdigital