import React from 'react'
import { Link } from 'react-router-dom';

class Formationtechnologique extends React.Component {

  render() {
    return (
      <div>
        <div className="container-fluid bg-primary py-5 bg-header" style={{ marginBottom: 90 }}>
          <div className="row py-5">
            <div className="col-12 pt-lg-5 mt-lg-5 text-center">
              <h1 className="display-4 text-white animated zoomIn">Formation Technologique</h1>
              <Link to="/" className="h5 text-white">Accueil</Link>
              <i className="far fa-circle text-white px-2" />
              <Link to="" className="h5 text-white">Formation Technologique</Link>
            </div>
          </div>
        </div>




        <div className="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{maxWidth: 600}}>
        <h5 className="fw-bold text-primary text-uppercase">Nos Formations</h5>
        <h1 className="mb-0">Profitez de nos formations certifiées</h1>
      </div>



        <div className="row g-5">
          <div className="col-lg-3 col-md-6 wow zoomIn" data-wow-delay="0.9s">
            <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
              {/* <div className="service-icon">
              <i className="fa fa-code text-white" />
            </div> */}
              <h4 className="mb-3" style={{ fontSize: 18 }}> Marketing Digital</h4>
              <p className="m-0">Maîtriser les bases du marketing numérique (gestion des médias sociaux, publicité, référencement, marketing de contenu, etc.)<br /><br /><br /><br /></p>

            </div>
          </div>
          <div className="col-lg-3 col-md-6 wow zoomIn" data-wow-delay="0.9s">
            <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
              {/* <div className="service-icon">
              <i className="fa fa-code text-white" />
            </div> */}
              <h4 className="mb-3" style={{ fontSize: 18 }}>Community Management</h4>
              <p className="m-0">Définir et établir une communauté, développer des stratégies et des processus communautaires, engager et modérer une communauté, mesurer et analyser, etc. <br /><br /><br /><br /></p>

            </div>
          </div>

          <div className="col-lg-3 col-md-6 wow zoomIn" data-wow-delay="0.9s">
            <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
              {/* <div className="service-icon">
              <i className="fa fa-code text-white" />
            </div> */}
              <h4 className="mb-3" style={{ fontSize: 18 }}>Création de sites web</h4>
              <p className="m-0">Développement Web, CCS, Html5, JavaScript, Responsive Web Design, Web Accessibility, Bootstrap (Front-End Framework), etc. <br /><br /><br /><br /></p>

            </div>
          </div>


          <div className="col-lg-3 col-md-6 wow zoomIn" data-wow-delay="0.9s">
            <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
              {/* <div className="service-icon">
              <i className="fa fa-code text-white" />
            </div> */}
              <h4 className="mb-3" style={{ fontSize: 18 }}>Design graphique </h4>
              <p className="m-0">Fondamentaux du graphisme, Introduction à la typographie,Introduction à la création d'images, etc. <br /><br /><br /><br /></p>

            </div>
          </div>


        </div>
        <br/><br/>

        <div className="row g-5">
          <div className="col-lg-3 col-md-6 wow zoomIn" data-wow-delay="0.9s">
            <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
              {/* <div className="service-icon">
              <i className="fa fa-code text-white" />
            </div> */}
              <h4 className="mb-3" style={{ fontSize: 18 }}> Business Intelligence</h4>
              <p className="m-0">Statistiques, SQL, Tableau et résolution de problèmes, collectez, organisez, analysez et visualisez des données, présentez des informations sous forme de métriques, de KPI, de rapports et de tableaux de bord, analysez des données actuelles et historiques, etc.<br /><br /><br /><br /></p>

            </div>
          </div>
          <div className="col-lg-3 col-md-6 wow zoomIn" data-wow-delay="0.9s">
            <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
              {/* <div className="service-icon">
              <i className="fa fa-code text-white" />
            </div> */}
              <h4 className="mb-3" style={{ fontSize: 18 }}>Intelligence Artificielle</h4>
              <p className="m-0">Langages de programmation (Python, R, Java), Algèbre linéaire et statistiques, Techniques de traitement du signal, Architectures de réseaux de neurones, etc. <br /><br /><br /><br /></p>

            </div>
          </div>

          <div className="col-lg-3 col-md-6 wow zoomIn" data-wow-delay="0.9s">
            <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
              {/* <div className="service-icon">
              <i className="fa fa-code text-white" />
            </div> */}
              <h4 className="mb-3" style={{ fontSize: 18 }}>Data Science</h4>
              <p className="m-0">Programmation, connaissance de SAS et d'autres outils d'analyse, capacité à travailler avec des données non structurées, etc. <br /><br /><br /><br /></p>

            </div>
          </div>


          <div className="col-lg-3 col-md-6 wow zoomIn" data-wow-delay="0.9s">
            <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
              {/* <div className="service-icon">
              <i className="fa fa-code text-white" />
            </div> */}
              <h4 className="mb-3" style={{ fontSize: 18 }}>Robotique</h4>
              <p className="m-0">Introduction à la robotique aérienne, à la géométrie et à la mécanique, à la planification et au contrôle, permettent aux quadrotors d'effectuer des manœuvres plus agiles, etc. <br /><br /><br /><br /></p>

            </div>
          </div>



        </div>

        <br/><br/>


        <div className="row g-5">
          <div className="col-lg-3 col-md-6 wow zoomIn" data-wow-delay="0.9s">
            <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
              {/* <div className="service-icon">
              <i className="fa fa-code text-white" />
            </div> */}
              <h4 className="mb-3" style={{ fontSize: 18 }}> Programme pour enfants</h4>
              <p className="m-0">Clubs de langues, club de littérature, club de peinture, etc.<br /><br /><br /><br /></p>

            </div>
          </div>
          <div className="col-lg-3 col-md-6 wow zoomIn" data-wow-delay="0.9s">
            <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
              {/* <div className="service-icon">
              <i className="fa fa-code text-white" />
            </div> */}
              <h4 className="mb-3" style={{ fontSize: 18 }}>Gestion de projet</h4>
              <p className="m-0">Plan projects from conception to implementation, map out timelines, assess project risks and opportunities, Execute each phase of the project life cycle, etc. <br /><br /><br /><br /></p>

            </div>
          </div>

          <div className="col-lg-3 col-md-6 wow zoomIn" data-wow-delay="0.9s">
            <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
              {/* <div className="service-icon">
              <i className="fa fa-code text-white" />
            </div> */}
              <h4 className="mb-3" style={{ fontSize: 18 }}>Montage vidéo</h4>
              <p className="m-0">Comprendre la vidéo numérique : fondements du montage Premiere Pro, Apprendre l'interface Premiere Pro, Apprendre à monter des clips dans la chronologie Premiere Pro, Utiliser les transitions vidéo et audio dans Premiere Pro <br /><br /><br /><br /></p>

            </div>
          </div>


          <div className="col-lg-3 col-md-6 wow zoomIn" data-wow-delay="0.9s">
            <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
              {/* <div className="service-icon">
              <i className="fa fa-code text-white" />
            </div> */}
              <h4 className="mb-3" style={{ fontSize: 18 }}>2D/3D</h4>
              <p className="m-0">Dessin de vie, introduction aux fondamentaux de l'animation, technique d'animation expérimentale, histoire de l'animation, introduction aux médias numériques, personnage et arrière-plan, etc. <br /><br /><br /><br /></p>

            </div>
          </div>



        </div>

        <br/><br/>


        <div className="row g-5">
          <div className="col-lg-3 col-md-6 wow zoomIn" data-wow-delay="0.9s">
            <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
              {/* <div className="service-icon">
              <i className="fa fa-code text-white" />
            </div> */}
              <h4 className="mb-3" style={{ fontSize: 18 }}> Soft Skills</h4>
              <p className="m-0">Communication, gestion des personnes, collaboration et travail d'équipe, gestion du temps, résolution de conflits, intelligence émotionnelle, etc.<br /><br /><br /><br /></p>

            </div>
          </div>
          <div className="col-lg-3 col-md-6 wow zoomIn" data-wow-delay="0.9s">
            <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
              {/* <div className="service-icon">
              <i className="fa fa-code text-white" />
            </div> */}
              <h4 className="mb-3" style={{ fontSize: 18 }}>Blockchain</h4>
              <p className="m-0">Fondements de la blockchain, fonctions de hachage et cryptographie, blockchain et blocs, signature de clé publique et cryptographie, la chaîne, le réseau et les nœuds, etc. <br /><br /><br /><br /></p>

            </div>
          </div>


        </div>




      </div>
    );
  }
}
export default Formationtechnologique