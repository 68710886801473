import React from 'react'
import{Link} from 'react-router-dom';

class Incubation extends React.Component {

  render() {
    return (
        <div>
                <div className="container-fluid bg-primary py-5 bg-header" style={{marginBottom: 90}}>
                  <div className="row py-5">
                    <div className="col-12 pt-lg-5 mt-lg-5 text-center">
                      <h1 className="display-4 text-white animated zoomIn">Incubation</h1>
                      <Link to="/Home"  className="h5 text-white">Accueil</Link>
                      <i className="far fa-circle text-white px-2" />
                      <Link to=""  className="h5 text-white">Incubations</Link>
                    </div>
                  </div>
                </div>
            

                <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
    <div className="container py-5">
      <div className="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{maxWidth: 600}}>
        <h5 className="fw-bold text-primary text-uppercase">NOS OFFRES</h5>
        <p >Icstartup s’engage à vos côtés dans la concrétisation de votre projet en entreprise, de la création au démarrage commercial .Cet accompagnement comprend également une assistance administrative et juridique grâce à notre pôle RH.</p>
      </div>
      <div className="row g-5">
        <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.9s">
          <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
            
            <h4 className="mb-3" style={{fontSize: 18}}> Pre-incubation</h4>
            <p className="m-0">Workshop idéation pour identifier le besoin Mise en évidence de la valeur ajoutée du projet  <br /><br /><br /><br /></p>
          
          </div>
        </div>
      
       
        
        <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
          <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
           
            <h4 className="mb-3"style={{fontSize: 18}}>Incubation </h4>
            <p className="m-0">Valider le business model Acquérir vos premiers clients Établir et engager une stratégie de financement<br /><br /><br /><br /></p>
           
          </div>
        </div>

        <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.9s">
          <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
           
            <h4 className="mb-3"style={{fontSize: 18}}>Acceleration</h4>
            <p className="m-0">Support pour la levée de fond Support en communication Mise en relation ciblée avec les investisseurs  <br /><br /><br /><br /></p>
           
          </div>
        </div>
      
      </div>
    </div>
  </div>



        </div>
    );
  }
}
export default Incubation