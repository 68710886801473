import React from 'react'
import{Link} from 'react-router-dom';

class Coworkingspace extends React.Component {

  render() {
    return (
        <div>
                <div className="container-fluid bg-primary py-5 bg-header" style={{marginBottom: 90}}>
                  <div className="row py-5">
                    <div className="col-12 pt-lg-5 mt-lg-5 text-center">
                      <h1 className="display-4 text-white animated zoomIn">Coworking Space</h1>
                      <Link to="/Home"  className="h5 text-white">Accueil</Link>
                      <i className="far fa-circle text-white px-2" />
                      <Link to=""  className="h5 text-white">Coworking Space</Link>
                    </div>
                  </div>
                </div>
            

                <div className="row g-5">
          <div className="col-lg-3 col-md-6 wow zoomIn" data-wow-delay="0.9s">
            <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
              {/* <div className="service-icon">
              <i className="fa fa-code text-white" />
            </div> */}
              <h4 className="mb-3" style={{ fontSize: 18 }}>Equipements</h4>
              <p className="m-0">Un open space équipé : postes individuels, une table, une chaise confortable et des rangements.<br /><br /><br /><br /></p>
              
            </div>
          </div>
          <div className="col-lg-3 col-md-6 wow zoomIn" data-wow-delay="0.9s">
            <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
              {/* <div className="service-icon">
              <i className="fa fa-code text-white" />
            </div> */}
              <h4 className="mb-3" style={{ fontSize: 18 }}>Espace cuisine</h4>
              <p className="m-0">Un réfrigérateur, machine à café, four micro-ondes, évier, une cafétéria avec des comptoirs mange-debout... <br /><br /><br /><br /></p>
            
            </div>
          </div>

          <div className="col-lg-3 col-md-6 wow zoomIn" data-wow-delay="0.9s">
            <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
              {/* <div className="service-icon">
              <i className="fa fa-code text-white" />
            </div> */}
              <h4 className="mb-3" style={{ fontSize: 18 ,marginTop:50 }}>Sécurité et netoyage</h4>
              <p className="m-0">Un système de sécurisation est assuré 24/24 ainsi que toute la maintenance nécessaire pour le local du coworking, l'espace est régulièrement nettoyé par une femme de ménage. <br /><br /><br /><br /></p>
           
            </div>
          </div>


          <div className="col-lg-3 col-md-6 wow zoomIn" data-wow-delay="0.9s">
            <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
              {/* <div className="service-icon">
              <i className="fa fa-code text-white" />
            </div> */}
              <h4 className="mb-3" style={{ fontSize: 18 }}>Connection haut débit </h4>
              <p className="m-0">Chaque poste de travail est connecté internet haut débit par la fibre optique. <br /><br /><br /><br /></p>
           
            </div>
          </div>



        </div>

        </div>
    );
  }
}
export default Coworkingspace