import { useRef } from 'react'; 
import emailjs from 'emailjs-com';
import swal from 'sweetalert';
import { Link } from 'react-router-dom';
export default function Devis() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_sq4hh8m', 'template_2p7mwal', form.current, '0lWVIqTi320ovPKAL')
      .then((result) => {
          
          swal("seccessful !...", "Votre message a été envoyé !", "success");
      }, (error) => {
          swal("Error!...", "Vous avez une erreur de saisie !", "error");
      });
  };
      
        return (

            <div>
         <div className="container-fluid bg-primary py-5 bg-header" style={{marginBottom: 90}}>
  <div className="row py-5">
    <div className="col-12 pt-lg-5 mt-lg-5 text-center">
      <h1 className="display-4 text-white animated zoomIn">Devis </h1>
      <Link to="/"  className="h5 text-white">Accueil</Link>
      <i className="far fa-circle text-white px-2" />
      <Link to="" className="h5 text-white">Contact</Link>
    </div>
  </div>
</div>


    <div>
    <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
      <div className="container py-5">
        <div className="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{maxWidth: 600}}>
          <h5 className="fw-bold text-primary text-uppercase">Devis</h5>
          <h1 className="mb-0">Demander votre devis</h1>
        </div>
        
        <div className="row g-12">
          <div className="col-lg-12 wow slideInUp" data-wow-delay="0.3s">
            <form ref={form} onSubmit={sendEmail} method="POST">
              <div className="row g-3">
                <div className="col-md-6">
                  <input type="text" name="from_name" className="form-control border-0 bg-light px-4" placeholder="Nom" style={{height: 55}} required />
                </div>
                <div className="col-md-6">
                  <input type="text" name="from_prenom"  className="form-control border-0 bg-light px-4" placeholder="Prénom" style={{height: 55}} required />
                </div>

                <div className="col-md-6">
                  <input type="text" name="from_tel" className="form-control border-0 bg-light px-4" placeholder="Téléphone(+216 ** *** ***)" style={{height: 55}} required />
                </div>
                <div className="col-md-6">
                <input type="email" name="from_email"  className="form-control border-0 bg-light px-4" placeholder="Votre Email" style={{height: 55}} required />
                </div>


                <div className="col-12">
                  <input type="texte" name="from_adress"  className="form-control border-0 bg-light px-4" placeholder="Votre adresse" style={{height: 55}} required />
                </div>

                <div className="col-md-6">
                  <input type="text" name="from_ville" className="form-control border-0 bg-light px-4" placeholder="Ville" style={{height: 55}} required />
                </div>
                <div className="col-md-6">
                <input type="text" name="from_postcode"  className="form-control border-0 bg-light px-4" placeholder="Code postal" style={{height: 55}} required />
                </div>


                <div className="col-12">
                  <select name="from_option"  className="form-control border-0 bg-light px-4" placeholder="options" style={{height: 55}} required >
                    <option value='1'>Vous souhaitez</option>
                    <option value="Présenter votre activité sur Internet , Création complète d'un site Internet Vitrine">Présenter votre activité sur Internet , Création complète d'un site Internet Vitrine</option>
                    <option value="Créer, décliner votre identité visuelle , Créations graphiques (logo, dépliant, flyer, ...)">Créer, décliner votre identité visuelle , Créations graphiques (logo, dépliant, flyer, ...)</option>
                    <option value="Vendre vos produits en ligne , Création complète d'un site Internet e-commerce">Vendre vos produits en ligne , Création complète d'un site Internet e-commerce</option>
                    <option value="Faire modifier votre site  existant , Maintenance">Faire modifier votre site  existant , Maintenance</option>
                    <option value="Améliorer vos performances e-commerce , Modules complémentaires pour Wordpress">Améliorer vos performances e-commerce , Modules complémentaires pour Wordpress</option>
                    <option value="Compléter votre site Internet selon vos besoins spécifiques , Développement ponctuel (modules ou fonctionnalités sur-mesure)">Compléter votre site Internet selon vos besoins spécifiques , Développement ponctuel (modules ou fonctionnalités sur-mesure)</option>
                    <option value="Déléguer la mise à jour régulière , Actualisation de site web">Déléguer la mise à jour régulière , Actualisation de site web</option>
                    <option value="Booster votre visibilité web sur Google , Référencement naturel, Adwords">Booster votre visibilité web sur Google , Référencement naturel, Adwords</option>
                    <option value="Communiquer auprès de votre communauté , Animation de vos réseaux sociaux (Facebook, Instagram, ...)">Communiquer auprès de votre communauté , Animation de vos réseaux sociaux (Facebook, Instagram, ...)</option>
                    <option value="Disposer d'un site rapide , Hébergement de sites web / Infogérance serveur">Disposer d'un site rapide , Hébergement de sites web / Infogérance serveur</option>
                    


                      </select>
                </div>

                <div className="col-12">
                  <input type="text" name="from_website" className="form-control border-0 bg-light px-4" placeholder="Website : Si vous disposez déjà d'un site Internet, merci de préciser son adresse URL : www.monsite.com" style={{height: 55}} />
                </div>

                <div className="col-12">
                  <input type="text" name="from_system" className="form-control border-0 bg-light px-4" placeholder="Pour ce site, système utilisé : Si vous en avez connaissance ! CMS Wordpress, solution Prestashop, autre ..." style={{height: 55}} />
                </div>



                <div className="col-12">
                  <textarea name="Description"  className="form-control border-0 bg-light px-4 py-3" rows={4} placeholder="Objectifs, éléments de référence, ... Plus la demande est précise, plus nous pourrons vous répondre rapidement." required defaultValue={""} />
                </div>
             
            
                <div className="col-12">
                  <button className="btn btn-primary w-100 py-3" type="submit">Envoyer</button>
                </div>


                
              </div>
              
            </form>
          </div>
          

          

          </div>
        </div>
      </div>
    </div>
    {/* Contact End */}
   
  </div>


 
        );
  }
