import React from 'react'
import { Link } from 'react-router-dom';

class About extends React.Component {

  render() {
    return (
      <div>
        <div className="container-fluid bg-primary py-5 bg-header" style={{ marginBottom: 90 }}>
          <div className="row py-5">
            <div className="col-12 pt-lg-5 mt-lg-5 text-center">
              <h1 className="display-4 text-white animated zoomIn">A Propos</h1>
              <Link to="/" className="h5 text-white">Accueil</Link>
              <i className="far fa-circle text-white px-2" />
              <Link to="" className="h5 text-white">A Propos</Link>
            </div>
          </div>
        </div>
        <div>
          <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
            <div className="container py-5">
              <div className="row g-5">
                <div className="col-lg-7">
                  <div className="section-title position-relative pb-3 mb-5">
                    <h5 className="fw-bold text-primary text-uppercase">À PROPOS DE NOUS</h5>
                    <h1 className="mb-0">Icstartup</h1>
                  </div>
                  <p className="mb-4"> Icstartup est une entreprise où sont réunis tous les métiers du conseil , du design, de la production audiovisuelle , de la publicité , du développement web , du mobile et du l'accompagnement et marketing digital.</p>
                  <Link to="./Contact" className="btn btn-primary py-3 px-5 mt-3 wow zoomIn" data-wow-delay="0.9s">Contactez Nous</Link>
                </div>
                <div className="col-lg-5" style={{ minHeight: 500 }}>
                  <div className="position-relative h-100">
                    <img className="position-absolute w-100 h-100 rounded wow zoomIn" data-wow-delay="0.9s" src="./assets/img/Pacman-removebg-preview.png" style={{ objectFit: 'cover' }} alt='imagem' title='imagem' />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* About End */}
          <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s" style={{ marginTop: '-150px' }}>
            <div className="container py-5">
              <div className="row g-5">
                <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                  <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                    <img src="./assets/img/pacmen.png" style={{ width: 100 }} alt="" />
                    <h4 className="mb-3">NOTRE VISION</h4>
                    <p className="m-0">Développer un écosystème basé sur le Co-leadership, composé d'experts créatifs et socialement responsables<br /><br /><br /></p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                  <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                    <img src="./assets/img/pacmen.png" style={{ width: 100 }} alt="" />
                    <h4 className="mb-3">NOTRE MISSION</h4>
                    <p className="m-0">Créer, développer et optimiser l'activité des entreprises du monde entier en utilisant la technologie et la créativité<br /><br /><br /></p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                  <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                    <img src="./assets/img/pacmen.png" style={{ width: 100 }} alt="" />
                    <h4 className="mb-3">NOS VALEURS</h4>
                    <p className="m-0">Nous construisons avec nos clients des liens individuels forts, basés sur l'écoute et le conseil.<br /><br /><br /></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Team Start */}
          <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
            <div className="container py-5">
              <div className="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{ maxWidth: 600 }}>
                <h5 className="fw-bold text-primary text-uppercase">Membres de l'équipe</h5>
                <h1 className="mb-0">Equipes professionnels prêts à aider votre entreprise</h1>
              </div>
              <div className="row g-5">
                <div className="col-lg-3 wow slideInUp" data-wow-delay="0.3s">
                  <div className="team-item bg-light rounded overflow-hidden">
                    <div className="team-img position-relative overflow-hidden">
                      <img className="img-fluid w-100" src="./assets/img/m3-team-2.png" alt="" />
                      <div className="team-social">
                       
                        <a href="https://www.linkedin.com/in/imen-jabri-340b58190/" className="btn btn-lg btn-primary btn-lg-square rounded" target = "_blank" rel='noreferrer'><i className="fab fa-linkedin-in fw-normal" /></a>
                      </div>
                    </div>
                    <div className="text-center py-4">
                      <h4 className="text-primary">Imen Jabri</h4>
                      <p className="text-uppercase m-0">CEO</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 wow slideInUp" data-wow-delay="0.6s">
                  <div className="team-item bg-light rounded overflow-hidden">
                    <div className="team-img position-relative overflow-hidden">
                      <img className="img-fluid w-100" src="./assets/img/m3-team-1.png" alt="" />
                      <div className="team-social">
                        <a href="https://www.linkedin.com/in/bilel-amdouni-075b6617b/" className="btn btn-lg btn-primary btn-lg-square rounded" target = "_blank" rel='noreferrer'><i className="fab fa-linkedin-in fw-normal" /></a>
                      </div>
                    </div>
                    <div className="text-center py-4">
                      <h4 className="text-primary">Bilel Amdouni</h4>
                      <p className="text-uppercase m-0">CTO-Cofounder</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 wow slideInUp" data-wow-delay="0.9s">
                  <div className="team-item bg-light rounded overflow-hidden">
                    <div className="team-img position-relative overflow-hidden">
                      <img className="img-fluid w-100" src="./assets/img/m3-team-3.png" alt="" />
                      <div className="team-social">
                       
                        <a href="https://www.linkedin.com/in/mohamed-ali-belajouza/" className="btn btn-lg btn-primary btn-lg-square rounded" target = "_blank" rel='noreferrer'><i className="fab fa-linkedin-in fw-normal" /></a>
                      </div>
                    </div>
                    <div className="text-center py-4">
                      <h4 className="text-primary">Dali Belajouza</h4>
                      <p className="text-uppercase m-0">CBO</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 wow slideInUp" data-wow-delay="0.9s">
                  <div className="team-item bg-light rounded overflow-hidden">
                    <div className="team-img position-relative overflow-hidden">
                      <img className="img-fluid w-100" src="./assets/img/m3-team-2.png" alt="" />
                      <div className="team-social">
                        <a href="https://www.linkedin.com/in/salwa-mekni-36604b96/" className="btn btn-lg btn-primary btn-lg-square rounded" target = "_blank" rel='noreferrer'><i className="fab fa-linkedin-in fw-normal" /></a>
                      </div>
                    </div>
                    <div className="text-center py-4">
                      <h4 className="text-primary">Salwa Mekni</h4>
                      <p className="text-uppercase m-0">team leader</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 wow slideInUp" data-wow-delay="0.9s">
                  <div className="team-item bg-light rounded overflow-hidden">
                    <div className="team-img position-relative overflow-hidden">
                      <img className="img-fluid w-100" src="./assets/img/m3-team-4.png" alt="" />
                      <div className="team-social">
                        <a href="https://www.linkedin.com/in/hella-jebali-01b2211b6/" className="btn btn-lg btn-primary btn-lg-square rounded" target = "_blank" rel='noreferrer'><i className="fab fa-linkedin-in fw-normal" /></a>
                      </div>
                    </div>
                    <div className="text-center py-4">
                      <h4 className="text-primary">Hela Jebali</h4>
                      <p className="text-uppercase m-0">Account Manager</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 wow slideInUp" data-wow-delay="0.9s">
                  <div className="team-item bg-light rounded overflow-hidden">
                    <div className="team-img position-relative overflow-hidden">
                      <img className="img-fluid w-100" src="./assets/img/m3-team-2.png" alt="" />
                      <div className="team-social">
                        <a href="https://www.linkedin.com/in/mouna-klai-a363261a1/" className="btn btn-lg btn-primary btn-lg-square rounded" target = "_blank" rel='noreferrer'><i className="fab fa-linkedin-in fw-normal" /></a>
                      </div>
                    </div>
                    <div className="text-center py-4">
                      <h4 className="text-primary">Mouna Klai</h4>
                      <p className="text-uppercase m-0">Digital Marketer</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 wow slideInUp" data-wow-delay="0.9s">
                  <div className="team-item bg-light rounded overflow-hidden">
                    <div className="team-img position-relative overflow-hidden">
                      <img className="img-fluid w-100" src="./assets/img/m3-team-4.png" alt="" />
                      <div className="team-social">
                        <a href="https://www.linkedin.com/in/yasmina-rebai-/" className="btn btn-lg btn-primary btn-lg-square rounded" target = "_blank" rel='noreferrer'><i className="fab fa-linkedin-in fw-normal" /></a>
                      </div>
                    </div>
                    <div className="text-center py-4">
                      <h4 className="text-primary">Yasmina Rebai</h4>
                      <p className="text-uppercase m-0">Digital Marketer</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 wow slideInUp" data-wow-delay="0.9s">
                  <div className="team-item bg-light rounded overflow-hidden">
                    <div className="team-img position-relative overflow-hidden">
                      <img className="img-fluid w-100" src="./assets/img/m3-team-2.png" alt="" />
                      <div className="team-social">
                        <a href="https://www.linkedin.com/in/ons-mansouri-9009301ab/" className="btn btn-lg btn-primary btn-lg-square rounded" target = "_blank" rel='noreferrer'><i className="fab fa-linkedin-in fw-normal" /></a>
                      </div>
                    </div>
                    <div className="text-center py-4">
                      <h4 className="text-primary">Ons Mansouri</h4>
                      <p className="text-uppercase m-0">Front-end developer</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 wow slideInUp" data-wow-delay="0.9s">
                  <div className="team-item bg-light rounded overflow-hidden">
                    <div className="team-img position-relative overflow-hidden">
                      <img className="img-fluid w-100" src="./assets/img/m3-team-3.png" alt="" />
                      <div className="team-social">

                        <a href="https://www.linkedin.com/in/makrem-jebali-006240190/" className="btn btn-lg btn-primary btn-lg-square rounded" target = "_blank" rel='noreferrer'><i className="fab fa-linkedin-in fw-normal" /></a>
                      </div>
                    </div>
                    <div className="text-center py-4">
                      <h4 className="text-primary">Makrem Jebali</h4>
                      <p className="text-uppercase m-0">Fullstack developer</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 wow slideInUp" data-wow-delay="0.9s">
                  <div className="team-item bg-light rounded overflow-hidden">
                    <div className="team-img position-relative overflow-hidden">
                      <img className="img-fluid w-100" src="./assets/img/m3-team-1.png" alt="" />
                      <div className="team-social">
                        <a href="https://www.linkedin.com/in/bilel-saidi-452764161/" className="btn btn-lg btn-primary btn-lg-square rounded" target = "_blank" rel='noreferrer'><i className="fab fa-linkedin-in fw-normal" /></a>
                      </div>
                    </div>
                    <div className="text-center py-4">
                      <h4 className="text-primary">Bilel Saidi</h4>
                      <p className="text-uppercase m-0">Fullstack developer</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 wow slideInUp" data-wow-delay="0.9s">
                  <div className="team-item bg-light rounded overflow-hidden">
                    <div className="team-img position-relative overflow-hidden">
                      <img className="img-fluid w-100" src="./assets/img/m3-team-3.png" alt="" />
                      <div className="team-social">

                        <a href="https://www.linkedin.com/in/baha-jemni-172a12180/" className="btn btn-lg btn-primary btn-lg-square rounded" target = "_blank" rel='noreferrer'><i className="fab fa-linkedin-in fw-normal" /></a>
                      </div>
                    </div>
                    <div className="text-center py-4">
                      <h4 className="text-primary">Baha Jemni</h4>
                      <p className="text-uppercase m-0">Fullstack developer</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 wow slideInUp" data-wow-delay="0.9s">
                  <div className="team-item bg-light rounded overflow-hidden">
                    <div className="team-img position-relative overflow-hidden">
                      <img className="img-fluid w-100" src="./assets/img/m3-team-1.png" alt="" />
                      <div className="team-social">
                       
                        <a href="/" className="btn btn-lg btn-primary btn-lg-square rounded" target = "_blank" rel='noreferrer'><i className="fab fa-linkedin-in fw-normal" /></a>
                      </div>
                    </div>
                    <div className="text-center py-4">
                      <h4 className="text-primary">Helmi Nefzi</h4>
                      <p className="text-uppercase m-0">Graphic designer</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Team End */}


        </div>

      </div>
    );
  }
}
export default About