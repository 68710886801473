import React from 'react';
import './App.css';


import Home from './Pages/Home';
import Header from './Component/Header';
import Footer from './Component/Footer';
import About from './Pages/About';
// import MessengerCustomerChat from 'react-messenger-customer-chat';
import Contact from './Pages/Contact';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Audiovisuelle from './Pages/Audiovisuelle';
import Developpementweb from './Pages/Developpementweb'
import Developpementlogiciel from './Pages/Developpementlogiciel';
import Design from './Pages/Design';
import Conseilconsulting from './Pages/Conseilconsulting';
import Developpementmobile from './Pages/Developpementmobile';
import Marketingdigital from './Pages/Marketingdigital'
import Incubation from './Pages/Incubation';
import Formationtechnologique from './Pages/Formationtechnologique';
import Coworkingspace from './Pages/Coworkingspace';
import Devis from './Pages/Devis';

function App() {
  return (
    <div>
      <Router>
        <Header />
        {/* <MessengerCustomerChat
    pageId="102040917960981"
    appId="347054360626359"
    htmlRef="<REF_STRING>"
  />, */}
        <Switch>

          <Route path='/' component = {Home} exact={true} />
          <Route path='/about' component = {About} />
          <Route path='/contact' component = {Contact} />
          <Route path='/audiovisuelle' component = {Audiovisuelle} />
          <Route path='/developpementweb' component = {Developpementweb} />
          <Route path='/developpementlogiciel' component = {Developpementlogiciel} />
         <Route path='/design' component={Design}/>
         <Route path='/conseilconsulting' component={Conseilconsulting}/>
         <Route path='/developpementmobile' component={Developpementmobile}/>
         <Route path='/marketingdigital' component={Marketingdigital}/>
         <Route path='/incubation' component={Incubation}/>
         <Route path='/formationtechnologique' component={Formationtechnologique}/>
         <Route path='/coworkingspace' component={Coworkingspace}/>
         <Route path='/devis' component={Devis}/>
        </Switch>
        <Footer />
      </Router>

     
    </div>
  
  
  );

}
export default App