import React from 'react'
import{Link} from 'react-router-dom';

class Design extends React.Component {

  render() {
    return (
        <div>
                <div className="container-fluid bg-primary py-5 bg-header" style={{marginBottom: 90}}>
                  <div className="row py-5">
                    <div className="col-12 pt-lg-5 mt-lg-5 text-center">
                      <h1 className="display-4 text-white animated zoomIn">Design graphique</h1>
                      <Link to="/"  className="h5 text-white">Accueil</Link>
                      <i className="far fa-circle text-white px-2" />
                      <Link to=""  className="h5 text-white">Design graphique</Link>
                    </div>
                  </div>
                </div>




                <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
                <div className="container py-5">
                  <div className="row g-5">
                    <div className="col-lg-7">
                      <div className="section-title position-relative pb-3 mb-5">
                        
                        <h1 className="mb-0">Design Graphique</h1>
                      </div>
                      <p className="mb-4">Les bases d’une communication réussie d’une entreprise résident dans le pouvoir attractif de son identité visuelle. L’image graphique est le meilleur moyen Marketing pour capturer l’attention. Cette tache doit être, de préférence, confiée à une agence de communication dynamique spécialisée afin d’avoir les meilleurs résultats possibles. Chez ICSTARTUP, nous vous aidons à faire une image d’étoile pour briller dans le ciel de votre marché. Nous mettons à votre service notre créativité pour des conceptions originales, aux normes de vos exigences.</p>
                     
                    </div>
                    <div className="col-lg-5" style={{minHeight: 500}}>
                      <div className="position-relative h-100">
                        <img className="position-absolute w-100 h-100 rounded wow zoomIn" data-wow-delay="0.9s" src="./assets/img/design.png" style={{objectFit: 'cover'}} alt='imagem' title='imagem'/>
                      </div>
                    </div>
                  </div>
                </div>
                </div>




                <div className="row g-5">
          <div className="col-lg-3 col-md-6 wow zoomIn" data-wow-delay="0.9s">
            <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
              {/* <div className="service-icon">
              <i className="fa fa-code text-white" />
            </div> */}
              <h4 className="mb-3" style={{ fontSize: 18 }}>Carte visite</h4>
              <p className="m-0">Une carte visite est plus qu’un support pour les coordonnées de votre entreprise ou de vos employés. Il s’agit d’une référence qui servira votre notoriété et votre présence sur le marché face à la concurrence.<br /></p>
              <Link to="./Devis" className="btn btn-lg btn-primary rounded"  style={{width:130,marginLeft:-65}}>
              Demander devis
            </Link>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 wow zoomIn" data-wow-delay="0.9s">
            <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
              {/* <div className="service-icon">
              <i className="fa fa-code text-white" />
            </div> */}
              <h4 className="mb-3" style={{ fontSize: 18 }}>Carte de vœux</h4>
              <p className="m-0">En plus du concept d’une simple forme de publicité, une carte de vœux est un outil de communication très apprécié et qui offre ses fruits pendant les célébrations. Une belle conception graphique permet l’élaboration d’un beau support à vos vœux. <br /></p>
              <Link to="./Devis" className="btn btn-lg btn-primary rounded"  style={{width:130,marginLeft:-65}}>
              Demander devis
            </Link>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 wow zoomIn" data-wow-delay="0.9s">
            <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
              {/* <div className="service-icon">
              <i className="fa fa-code text-white" />
            </div> */}
              <h4 className="mb-3" style={{ fontSize: 18 }}>Brochure</h4>
              <p className="m-0">L’élaboration d’un support représentatif de votre activité est un élément essentiel afin de familiariser votre clientèle avec vos services. Une brochure doit refléter la culture de votre entreprise pour pouvoir attirer la clientèle et consolider vos moyens de communication.<br /></p>
              <Link to="./Devis" className="btn btn-lg btn-primary rounded"  style={{width:130,marginLeft:-65}}>
              Demander devis
            </Link>
            </div>
          </div>


          <div className="col-lg-3 col-md-6 wow zoomIn" data-wow-delay="0.9s">
            <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
              {/* <div className="service-icon">
              <i className="fa fa-code text-white" />
            </div> */}
              <h4 className="mb-3" style={{ fontSize: 18 }}>Catalogue produits </h4>
              <p className="m-0">La présentation de vos produits est un facteur déterminant pour la réussite commerciale. En choisissant les bonnes images avec une présentation graphique attirante, vous pouvez garantir une meilleure communication.<br /></p>
              <Link to="./Devis" className="btn btn-lg btn-primary rounded"  style={{width:130,marginLeft:-65}}>
              Demander devis
            </Link>
            </div>
          </div>



        </div>



        </div>
    );
  }
}
export default Design